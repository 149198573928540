import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MapIcon from "@mui/icons-material/Map";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ExcelDownload from "../../Excel/ExcelDownload";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../../Assets/images/pin.png";
import Topbar from "../../global/Topbar";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";
import Checkbox from "@mui/material/Checkbox";
import CheckboxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { imageUrl } from "../../../Redux/apiConfig";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AdminVisits() {
  const dispatch = useDispatch();
  const Visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );
  console.log("Visits", Visits);
  const calculateTotalOrderValue = (visits) => {
    // Initialize total value
    let totalOrderValue = 0;

    // Check if visits is defined before iterating
    if (visits) {
      // Iterate through each visit
      visits.forEach((visit) => {
        // Check if the visit object has 'visits' property and 'total_order' property inside it
        if (visit && visit.visits && visit.visits?.total_order) {
          // Add the value of 'total_order' to the total order value
          totalOrderValue += parseInt(visit.visits.total_order, 10); // Convert to integer before adding
        }
      });
    }

    // Return the total order value
    return totalOrderValue;
  };

  const calculateTotalSuccessValue = (visits) => {
    // Initialize total value
    let totalOrderValue = 0;

    // Check if visits is defined before iterating
    if (visits) {
      // Iterate through each visit
      visits.forEach((visit) => {
        // Check if the visit object has 'visits' property and 'total_order' property inside it
        if (visit && visit.visits && visit.visits?.order_yes) {
          // Add the value of 'total_order' to the total order value
          totalOrderValue += parseInt(visit.visits.order_yes, 10); // Convert to integer before adding
        }
      });
    }

    // Return the total order value
    return totalOrderValue;
  };
  const totalOrderValue = calculateTotalOrderValue(Visits);
  const totalsuccessValue = calculateTotalSuccessValue(Visits);
  const percentage = (totalsuccessValue / totalOrderValue) * 100;

  const negativeCalls = totalOrderValue - totalsuccessValue;

  console.log("Percentage:", percentage);
  const loading = useSelector((state) => state.ViewAdminVisits.isLoader);
  const error = useSelector((state) => state.ViewAdminVisits.isError);
  const [filterYear, setFilterYear] = React.useState("");
  const [filterMonth, setFilterMonth] = React.useState("");
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState({
    name: [],
    empCode: [],
  });

  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    dispatch(fetchViewAdminVisits());
  }, [dispatch]);

  const [searchTerm, setSearchTerm] = React.useState("");
  // ======================
  const searchData = Visits?.filter(
    (visitData) =>
      (visitData.visits.concerned_person?.toLowerCase() ?? "").includes(
        searchTerm
      ) ||
      (visitData.visits.firm_name?.toLowerCase() ?? "").includes(searchTerm)
  );

  const filteredDataByData = searchData
    ? searchData.filter((visitData) => {
        const date = new Date(visitData.visits.created_at);
        const visitYear = date.getFullYear();
        const visitMonth = date.getMonth() + 1; // Month starts from 0

        return (
          (!filterYear || visitYear === parseInt(filterYear)) &&
          (!filterMonth || visitMonth === parseInt(filterMonth))
        );
      })
    : [];

  const slicedData = filteredDataByData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const uniqueYears = Visits
    ? [
        ...new Set(
          Visits.map((visitData) =>
            new Date(visitData.visits.created_at).getFullYear()
          )
        ),
      ]
    : [];

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (AllVisitsUser) => {
    const latitude = parseFloat(AllVisitsUser?.visits.visit_latitude);
    const longitude = parseFloat(AllVisitsUser?.visits.visit_longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
      toast.error("Latitude and/or Longitude not available for this Visit.");
      return;
    }

    console.log("Latitude:", latitude);
    console.log("Longitude:", longitude);

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };

  // Define a function to format the date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(2);
    return `${day}-${month}-${year}`;
  }

  // Define a function to format the time
  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <h4 style={{ color: "#ffffff" }}>Visits Details</h4>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            // startIcon={<AddIcon />}
            onClick={donloadexcel}
          >
            Download Excel
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              id="id-dropdown"
              options={
                Array.isArray(Visits)
                  ? filteredDataByData.map((Visits) => Visits.visits.id)
                  : []
              }
              getOptionLabel={(option) =>
                filteredDataByData.find(
                  (Visits) => Visits.visits?.id === option
                )?.visits.user_name.empCode || ""
              }
              disableCloseOnSelect
              onChange={(event, values) =>
                setSelectedFilterOptions((prevOptions) => ({
                  ...prevOptions,
                  id: values,
                }))
              }
              value={selectedFilterOptions.visits?.id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckboxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckboxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {Visits.find((Visits) => Visits.visits?.id === option)?.visits
                    .user_name.empCode || ""}
                </li>
              )}
              style={{
                width: "200px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter by Emp Code"
                  sx={{
                    "& .MuiInputBase-root": {
                      paddingLeft: "5px", // Adjust the left padding
                      paddingRight: "5px", // Adjust the right padding
                      borderRadius: "5px", // Set the desired border radius
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      backgroundColor: "white", // Set the background color for the dropdown
                    },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <span
                    {...getTagProps({ index })}
                    key={index}
                    style={{ display: "none" }}
                  />
                ))
              }
            />
            <InputBase
              sx={{ ml: 2, width: "220px" }}
              placeholder="Search by Firm or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                outline: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
            />
            {/* Year filter */}
            <select
              value={filterYear}
              onChange={(e) => setFilterYear(e.target.value)}
              style={{
                padding: "7px",
                margin: "0 10px",
                borderRadius: "5px",
              }}
            >
              <option value="">Year</option>
              {/* Populate options for years */}
              {uniqueYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {/* Month filter */}
            <select
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
              style={{
                padding: "7px",
                borderRadius: "5px",
              }}
            >
              <option value="">Month</option>
              {/* Populate options for months */}
              {[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ].map((month, index) => (
                <option key={index + 1} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Grid container spacing={3} style={{ padding: "10px" }}>
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#f8ee1f" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Total Visits
                  </Typography>
                  <Typography variant="h2" component="div">
                    {" "}
                    {totalOrderValue}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#e739fc" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Productive Calls
                  </Typography>
                  <Typography variant="h2" component="div">
                    {calculateTotalSuccessValue(Visits)}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>{" "}
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#ff7e7e" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Negative Calls
                  </Typography>
                  <Typography variant="h2" component="div">
                    {negativeCalls}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#95fba0" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Productivity
                  </Typography>
                  <Typography variant="h2" component="div">
                    {percentage}%
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : Visits && Visits.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Type
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Remarks
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Geotagged
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Distance
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Date
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Time
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Material Dispatched
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Order Received
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Total Visits
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Productive Calls
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Productivity
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Images
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData.map((entry, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {index + 1 + page * rowsPerPage}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {entry.visits.emp_code}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {entry.visits.category}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.firm_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.concerned_person}
                        </StyledTableCell>
                        <StyledTableCell>{entry.visits.type}</StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.remarks}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.visit_latitude &&
                          entry.visits.visit_longitude
                            ? "Yes"
                            : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.distance}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDate(entry.visits.created_at)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatTime(entry.visits.created_at)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.amount ? entry.visits.amount : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.order_recipt}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {entry.visits.total_order}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {entry.visits.order_yes}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.order_per}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.image &&
                            entry.image.startsWith(`${imageUrl}`) && (
                              <IconButton
                                href={entry.image}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                              >
                                <ImageOutlinedIcon />
                              </IconButton>
                            )}
                          {entry.image1 &&
                            entry.image1.startsWith(`${imageUrl}`) && (
                              <IconButton
                                href={entry.image1}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                              >
                                <ImageOutlinedIcon />
                              </IconButton>
                            )}
                          {entry.image2 &&
                            entry.image2.startsWith(`${imageUrl}`) && (
                              <IconButton
                                href={entry.image2}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                              >
                                <ImageOutlinedIcon />
                              </IconButton>
                            )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {entry.visits.visit_latitude &&
                          entry.visits.visit_longitude ? (
                            <IconButton
                              color="secondary"
                              onClick={() => handleOpenMapModal(entry)}
                            >
                              <MapIcon />
                            </IconButton>
                          ) : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, -1]}
              component="div"
              count={Visits.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Visits available</div>
        )}

        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              />
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div style={{ display: downloadExcel ? "block" : "none" }}>
        {downloadExcel ? (
          <ExcelDownload
            data={excelData}
            name="visits-data"
            onsubmit={disabledownload}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
