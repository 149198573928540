import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Paper,
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Button,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import Topbar from "../../global/Topbar";
import ExcelDownload from "../../Excel/ExcelDownload";
import axios from "axios";
import { fetchViewAdminClient } from "../../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
import { fetchViewAdminAllClient } from "../../../Redux/Slice/Admin/Report/ViewAllClientDataSlice";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

function ErrorComponent({ message }) {
  return <div style={{ color: "red" }}>{message}</div>;
}

export default function AdminReport() {
  const dispatch = useDispatch();

  const visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );

  const AllData = useSelector(
    (state) =>
      state.ViewAllClientData.ViewAdminAllClientData?.data?.AllData || []
  );
  console.log("visits", visits);
  const loading = useSelector((state) => state.ViewAllClientData.isLoader);
  const error = useSelector((state) => state.ViewAllClientData.isError);

  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  );

  React.useEffect(() => {
    dispatch(fetchViewAdminAllClient());
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewAdminVisits());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = debounce((event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  }, 300);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const generateYearRange = (numYears) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: numYears }, (_, index) => currentYear - index);
  };

  const yearOptions = generateYearRange(5);

  const filteredData = React.useMemo(() => {
    return (
      AllData?.filter(
        (client) =>
          (selectedEmpCodes.length === 0 ||
            selectedEmpCodes.includes(client?.emp_code)) &&
          (client?.empname?.toLowerCase().includes(searchTerm) ||
            client?.name?.toLowerCase().includes(searchTerm))
      )
        // Sorting based on categoery_id first and then alphabetically by name
        .sort((a, b) => {
          if (parseInt(a.categoery_id) !== parseInt(b.categoery_id)) {
            return parseInt(a.categoery_id) - parseInt(b.categoery_id);
          } else {
            return a.name.localeCompare(b.name);
          }
        })
    );
  }, [AllData, selectedEmpCodes, searchTerm]);

  const slicedData = React.useMemo(() => {
    return filteredData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [filteredData, page, rowsPerPage]);

  const categoryMapping = {
    SubDealer: "1",
    Dealer: "0",
    Architect: "5",
    Builder: "6",
    Contractor: "7",
    Competitor: "8",
    Other: "10",
  };

  const getVisitCount = (clientData, month) => {
    return visits?.reduce((count, visit) => {
      const visitDate = new Date(visit.visits.server_time);
      if (
        visit.visits.firm_id === clientData.id.toString() &&
        String(categoryMapping[visit.visits.category]) ===
          String(clientData.categoery_id) &&
        visitDate.getMonth() === month &&
        visitDate.getFullYear() === selectedYear
      ) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const getOrderYesCount = (clientData, month) => {
    return visits?.reduce((count, visit) => {
      const visitDate = new Date(visit.visits.server_time);
      if (
        visit.visits.firm_id === clientData.id.toString() &&
        String(categoryMapping[visit.visits.category]) ===
          String(clientData.categoery_id) &&
        visitDate.getMonth() === month &&
        visitDate.getFullYear() === selectedYear &&
        visit.visits.payment_status === "Yes"
      ) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const getTotalAmount = (clientData, month) => {
    return visits?.reduce((total, visit) => {
      const visitDate = new Date(visit.visits.server_time);
      if (
        visit.visits.firm_id === clientData.id.toString() &&
        String(categoryMapping[visit.visits.category]) ===
          String(clientData.categoery_id) &&
        visitDate.getMonth() === month &&
        visitDate.getFullYear() === selectedYear
      ) {
        // Convert amount to number and add it to total
        return total + parseFloat(visit.visits.amount || 0);
      }
      return total;
    }, 0);
  };

  const [downloadExcel, setdownloadExcel] = React.useState(false);
  const [excelData, setexcelData] = React.useState();

  // download excel
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };
  const [tableData, setTableData] = React.useState([]);
  console.log("tableData", tableData);
  React.useEffect(() => {
    const newData = [];
    slicedData?.forEach((clientData, index) => {
      const rowData = Array.from({ length: 12 }, (_, index) => {
        const visitCount = getVisitCount(clientData, index);
        const orderCount = getOrderYesCount(clientData, index);
        const totalAmount = getTotalAmount(clientData, index);
        return { visitCount, orderCount, totalAmount };
      });
      newData.push(rowData);
    });
    setTableData(newData);
  }, [slicedData]);
  const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
  };
  const sendTableDataToAPI = async () => {
    const token = getTokenFromLocalStorage();
    try {
      const response = await axios.post(
        "https://api.appris.in/public/api/email-report",
        tableData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("API Response:", response.data);
      // Handle success response from API if needed
    } catch (error) {
      console.error("Error:", error);
      // Handle error response from API if needed
    }
  };

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={6} md={6} lg={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={donloadexcel}
              style={{ marginBottom: "10px" }}
            >
              Download Excel
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={sendTableDataToAPI}
              style={{ marginBottom: "10px" }}
            >
              Send Table Data to API
            </Button>
          </Grid> */}
          <Grid item xs={6} md={6} lg={4} style={{ marginBottom: "10px" }}>
            <FormControl fullWidth>
              <h4 style={{ color: "#ffffff" }}>Reports Details</h4>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={6} lg={2}>
            <FormControl fullWidth>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                displayEmpty
                style={{ backgroundColor: "#fff", marginBottom: "10px" }}
              >
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <InputBase
              placeholder="Search by Emp Name or Client Name"
              onChange={handleSearch}
              sx={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                marginBottom: "10px",
              }}
            />
          </Grid>
        </Grid>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <ErrorComponent message={error.message} />
        ) : filteredData && filteredData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                      }}
                    >
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        borderRight: "1px solid #fff",
                      }}
                    >
                      Emp Name
                    </StyledTableCell>
                    {Array.from({ length: 12 }, (_, index) => {
                      const monthName = new Date(2024, index).toLocaleString(
                        "default",
                        { month: "long" }
                      );
                      return (
                        <React.Fragment key={index}>
                          <StyledTableCell
                            colSpan={3}
                            style={{
                              backgroundColor: "#7b0087",
                              textAlign: "center",
                              borderRight: "1px solid #fff",
                            }}
                          >
                            {monthName}
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>

                  <TableRow>
                    <StyledTableCell
                      style={{ backgroundColor: "#7b0087" }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#7b0087" }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#7b0087" }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#7b0087" }}
                    ></StyledTableCell>
                    <StyledTableCell
                      style={{
                        backgroundColor: "#7b0087",
                        borderRight: "1px solid #fff",
                      }}
                    ></StyledTableCell>
                    {Array.from({ length: 12 }, (_, index) => {
                      return (
                        <React.Fragment key={index}>
                          <StyledTableCell
                            style={{
                              backgroundColor: "#7b0087",
                            }}
                          >
                            Visit
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "#7b0087",
                            }}
                          >
                            Order
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "#7b0087",
                              borderRight: "1px solid #fff",
                            }}
                          >
                            Amount
                          </StyledTableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((clientData, index) => (
                    <StyledTableRow key={clientData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>{clientData.name}</StyledTableCell>

                      <StyledTableCell>
                        {(() => {
                          switch (String(clientData?.categoery_id)) {
                            case "0":
                              return "Distributor";
                            case "1":
                              return "Dealer";
                            case "5":
                              return "Architect";
                            case "6":
                              return "Builder/Contractor";
                            case "7":
                              return "Technician";
                            case "8":
                              return "Competitor";
                            case "10":
                              return "Other";
                            default:
                              return "";
                          }
                        })()}
                      </StyledTableCell>

                      <StyledTableCell>{clientData?.emp_code}</StyledTableCell>
                      <StyledTableCell
                        style={{ borderRight: "1px solid #ddd" }}
                      >
                        {clientData?.empname}
                      </StyledTableCell>
                      {tableData[index]?.map((rowData, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          <StyledTableCell>
                            {rowData.visitCount}
                          </StyledTableCell>
                          <StyledTableCell>
                            {rowData.orderCount}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            {rowData.totalAmount}
                          </StyledTableCell>
                        </React.Fragment>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </React.Fragment>
        ) : (
          <div>No data available</div>
        )}
        <div style={{ display: downloadExcel ? "block" : "none" }}>
          {downloadExcel ? (
            <ExcelDownload
              data={excelData}
              name="report-data"
              onsubmit={disabledownload}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
