import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import Topbar from "../global/Topbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchViewPjp } from "../../Redux/Slice/Pjp/ViewPjpSlice";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { fetchViewDashboard } from "../../Redux/Slice/Dashboard/ViewDashboardSlice";
import { Link } from "react-router-dom";
import { fetchViewClient } from "../../Redux/Slice/Client/ViewClientSlice";
import { fetchViewUser } from "../../Redux/Slice/Users/ViewUserSlice";
import { fetchViewAdminVisits } from "../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";
import { fetchViewAdminClient } from "../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
import { fetchViewAdminDealer } from "../../Redux/Slice/Admin/Dealer/ViewAdminDealerSlice";
import { fetchViewAdminSubdealer } from "../../Redux/Slice/Admin/Subdealer/ViewAdminSubdealerSlice";

const Dashboard = () => {
  const dispatch = useDispatch();

  const DashboardData = useSelector(
    (state) => state.ViewDashboard.ViewDashboardData
  );
  console.log("dash", Dashboard);
  const AllPjp = useSelector((state) => state.ViewPjp.ViewPjpData?.data);
  const countAllPjp = () => {
    if (!AllPjp || !Array.isArray(AllPjp)) {
      return 0;
    }
    return AllPjp.length;
  };
  //
  const DealerData = useSelector(
    (state) => state.ViewAdminDealer.ViewAdminDealerData?.data
  );
  const countDealer = () => {
    if (!DealerData || !Array.isArray(DealerData)) {
      return 0;
    }
    return DealerData.length;
  };
  //
  const SubdealerData = useSelector(
    (state) => state.ViewAdminSubdealer.ViewAdminSubdealerData?.data
  );
  const countSubdealer = () => {
    if (!SubdealerData || !Array.isArray(SubdealerData)) {
      return 0;
    }
    return SubdealerData.length;
  };
  const ClientData = useSelector(
    (state) => state.ViewAdminClients.ViewAdminClientData?.data
  );
  const countDealersByRole = (categoeryId) => {
    if (!ClientData || !ClientData || !Array.isArray(ClientData)) {
      return 0;
    }
    return ClientData.filter((Client) => Client.categoery_id === categoeryId)
      .length;
  };
  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);

  const countUser = () => {
    if (!EmpData || !Array.isArray(EmpData)) {
      return 0;
    }
    return EmpData.length;
  };

  //
  React.useEffect(() => {
    dispatch(fetchViewAdminDealer());
    dispatch(fetchViewPjp());
    dispatch(fetchViewDashboard());
    dispatch(fetchViewClient());
    dispatch(fetchViewUser());
    dispatch(fetchViewAdminVisits());
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewAdminSubdealer());
  }, [dispatch]);
  const prepareGraphData = () => {
    if (!DashboardData || typeof DashboardData !== "object") {
      return [];
    }

    const graphData = Object.keys(DashboardData).map((month) => ({
      month,
      value: DashboardData[month],
    }));

    return graphData;
  };
  const calculateTotalVisits = () => {
    if (!DashboardData || typeof DashboardData !== "object") {
      return 0;
    }

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });

    return DashboardData[currentMonth] || 0;
  };
  const Visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );
  console.log("Visits", Visits);

  // Filter out visits with duplicate emp_code
  const uniqueVisits =
    Visits?.reduce((unique, visit) => {
      // Check if the emp_code of the current visit already exists in the unique array
      const existingVisit = unique.find(
        (v) => v.visits.emp_code === visit.visits.emp_code
      );
      // If not found, add the visit to the unique array
      if (!existingVisit) {
        unique.push(visit);
      }
      return unique;
    }, []) || [];

  // Calculate total calls
  const totalCalls = uniqueVisits.reduce((total, visit) => {
    // Parse total_order to integer and add to total
    return total + parseInt(visit.visits.total_order);
  }, 0);

  const totalOrderYes = uniqueVisits.reduce((total, visit) => {
    // Add order_yes to total
    return total + parseInt(visit.visits.order_yes);
  }, 0);

  const Productivity = () => {
    return (totalOrderYes / totalCalls) * 100;
  };

  const totalVisits = calculateTotalVisits();

  const graphData = prepareGraphData();
  return (
    <div style={{ background: "linear-gradient(to right, #ffedff, #fff)" }}>
      <Topbar />
      <Grid container spacing={3} style={{ padding: "30px" }}>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f8ee1f", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Pjp
                </Typography>
                <Typography variant="h2" component="div">
                  {countAllPjp()}
                </Typography>
              </div>{" "}
              <Link to="/viewpjp">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#e739fc", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Distributor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealer()}
                </Typography>
              </div>{" "}
              <Link to="/Distributor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#95fba0", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Subdealer
                </Typography>
                <Typography variant="h2" component="div">
                  {countSubdealer()}
                </Typography>
              </div>{" "}
              <Link to="/subdealer">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f9bcff", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Architect
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("5")}
                </Typography>
              </div>{" "}
              <Link to="/architect">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#9fa3f2", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Builder
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("6")}
                </Typography>
              </div>{" "}
              <Link to="/builder">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#bf62ff", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Contractor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("7")}
                </Typography>
              </div>{" "}
              <Link to="/contractor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f9ff59", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Competitor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("8")}
                </Typography>
              </div>{" "}
              <Link to="/competitor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#fe8553", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Other
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole("10")}
                </Typography>
              </div>
              <Link to="/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#42eddf", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Total Users
                </Typography>
                <Typography variant="h2" component="div">
                  {countUser()}
                </Typography>
              </div>{" "}
              <Link to="/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f0ff95", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Total Calls
                </Typography>
                <Typography variant="h2" component="div">
                  {totalCalls}
                </Typography>
              </div>{" "}
              <Link to="/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#5cfd35", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Positive Calls
                </Typography>
                <Typography variant="h2" component="div">
                  {totalOrderYes}
                </Typography>
              </div>
              <Link to="/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>{" "}
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#e15ff4", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Productivity
                </Typography>
                <Typography variant="h2" component="div">
                  {Productivity().toFixed(2)}%
                </Typography>
              </div>{" "}
              <Link to="/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: "#90ff86",
              color: "#fff",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  variant="h2"
                  component="div"
                  style={{ color: "#000" }}
                >
                  Total Visits
                </Typography>
                <Typography
                  variant="h1"
                  component="div"
                  style={{ color: "#000" }}
                >
                  {totalVisits}
                </Typography>
              </div>
              <ResponsiveContainer height={400}>
                <BarChart data={graphData}>
                  <XAxis dataKey="month" tick={{ fill: "#000" }} />
                  <YAxis tick={{ fill: "#000" }} />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#fff",
                      color: "#d169dd",
                    }}
                  />
                  <Bar dataKey="value" fill="#d169dd" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
            <CardActions>
              <Link to="/visits">
                <Button size="small" style={{ color: "#000" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
