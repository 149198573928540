import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AccessibilityNewOutlinedIcon from "@mui/icons-material/AccessibilityNewOutlined";
import BoyOutlinedIcon from "@mui/icons-material/BoyOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TransferWithinAStationOutlinedIcon from "@mui/icons-material/TransferWithinAStationOutlined";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { version } from "../../Redux/apiConfig";
import TaskIcon from "@mui/icons-material/Task";
import SummarizeIcon from "@mui/icons-material/Summarize";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        setSelected(title);
        localStorage.setItem("selectedPage", title); // Save selected page to local storage
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SubMenuItem = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        marginLeft: "20px",
      }}
      onClick={() => {
        setSelected(title);
        localStorage.setItem("selectedPage", title); // Save selected page to local storage
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(
    localStorage.getItem("selectedPage") || "Dashboard"
  ); // Load selected page from local storage or default to "Dashboard"

  useEffect(() => {
    // Save default page to local storage on component mount if not already present
    if (!localStorage.getItem("selectedPage")) {
      localStorage.setItem("selectedPage", "Dashboard");
    }
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          height: "100%",
          overflowY: "auto",
        },

        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu
          iconShape="square"
          style={{
            background: "linear-gradient(to top, #2d0031, #4d0054)",
            height: "190%",
          }}
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[500],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h5" color={colors.grey[100]}>
                  PLUMBER PIPES
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon style={{ color: "white" }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {/* <Item
              title="Users"
              to="/users"
              icon={<AccountCircleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />{" "} */}
            <SubMenu
              title="Users"
              icon={<AccountCircleOutlinedIcon />}
              defaultOpen={
                selected.includes("Users") || selected.includes("Track User")
              }
            >
              <SubMenuItem
                title="Users"
                to="/users"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Spot User"
                to="/admin/track-user"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <Item
              title="Dashboard"
              to="/admin/dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="PJP"
              to="/admin/pjp"
              icon={<CardTravelIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Distributor"
              to="/admin/Distributor"
              icon={<AccessibilityNewOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Dealer"
              to="/admin/dealer"
              icon={<BoyOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Projects"
              to="/admin/projects"
              icon={<TaskIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              title="Clients"
              icon={<PeopleOutlinedIcon />}
              defaultOpen={
                selected.includes("Architect") ||
                selected.includes("Builder/Contractor") ||
                selected.includes("Technician") ||
                selected.includes("Competitor") ||
                selected.includes("Other")
              }
            >
              <SubMenuItem
                title="Architect"
                to="/admin/architect"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Builder/Contractor"
                to="/admin/builder-contractor"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Technician"
                to="/admin/technician"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Competitor"
                to="/admin/competitor"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Other"
                to="/admin/others"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              title="Visits"
              icon={<TransferWithinAStationOutlinedIcon />}
            >
              {/* <SubMenuItem
                title="View Visits"
                to="/admin/visits"
                selected={selected}
                setSelected={setSelected}
              />{" "} */}
              <SubMenuItem
                title="Weekly Visits"
                to="/admin/weekly-visits"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Monthly Visits"
                to="/admin/monthly-visits"
                selected={selected}
                setSelected={setSelected}
              />
              <SubMenuItem
                title="Visits On Map"
                to="/admin/map"
                selected={selected}
                setSelected={setSelected}
              />{" "}
            </SubMenu>{" "}
            <Item
              title="Report"
              to="/admin/report"
              icon={<SummarizeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>{" "}
        <Box style={{ background: "#2e0032", paddingLeft: "20px" }}>
          <Typography variant="caption" color={colors.grey[100]}>
            Made with
            <FavoriteIcon
              fontSize="small"
              color="error"
              style={{ position: "relative", top: "5px" }}
            />
            by Ruminate IT Solution
            <br />
            <Typography>{`${version}`}</Typography>
          </Typography>
        </Box>
      </ProSidebar>{" "}
    </Box>
  );
};

export default SidebarAdmin;
